import { Session } from "@supabase/supabase-js";
import { createEffect, createStore } from "effector";
import { supabase } from "shared/api/supabase";

const receiveFx = createEffect<void, Session>(async () => {
  const { data, error } = await supabase.auth.getSession();

  if (error) {
    throw error;
  }
  if (!data.session) {
    throw new Error("Session not found!");
  }
  console.log("receive session");

  return data.session;
});

const $session = createStore<Session | null>(null);
$session.on(receiveFx.doneData, (_, session) => session);

const $isAuthorized = $session.map(Boolean);

$session.watch((s) => console.log("Session: ", s));

export const session = {
  $session,
  $isAuthorized,
  receiveFx,
};
