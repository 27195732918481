import { HTMLMotionProps, motion, Transition, Variants } from "framer-motion";
import React from "react";
import { twMerge } from "tailwind-merge";

const dotsContainerVariants: Variants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const dotVariants: Variants = {
  start: {
    y: "-50%",
  },
  end: {
    y: "50%",
  },
};

const dotTransition: Transition = {
  duration: 0.6,
  repeat: Infinity,
  repeatType: "reverse",
  ease: "easeInOut",
};

type TDotsWaveLoaderOwnProps = {
  dotClassName?: string;
};
type TDotsWaveLoaderProps = HTMLMotionProps<"div"> & TDotsWaveLoaderOwnProps;

const _DotsWaveLoader = (props: TDotsWaveLoaderProps) => {
  const { className, dotClassName, ...containerProps } = props;

  return (
    <motion.div
      animate="end"
      className={twMerge(
        "relative flex items-center justify-center gap-2",
        className
      )}
      initial="start"
      variants={dotsContainerVariants}
      {...containerProps}
    >
      <motion.span
        className={twMerge("h-2 w-2 rounded-full bg-black", dotClassName)}
        transition={dotTransition}
        variants={dotVariants}
      />
      <motion.span
        className={twMerge("h-2 w-2 rounded-full bg-black", dotClassName)}
        transition={dotTransition}
        variants={dotVariants}
      />
      <motion.span
        className={twMerge("h-2 w-2 rounded-full bg-black", dotClassName)}
        transition={dotTransition}
        variants={dotVariants}
      />
    </motion.div>
  );
};

export const DotsWaveLoader = React.memo(_DotsWaveLoader);
