import "uno.css";
import "@unocss/reset/tailwind-compat.css";
import "./index.scss";
import "./init";

import { Routing } from "pages";
import { app } from "shared/libs/app";
import { router } from "shared/libs/router";

import { withProviders } from "./providers";

app.started();

const _App = () => {
  return <Routing />;
};

export const App = withProviders({ router })(_App);
