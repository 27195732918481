import { createClient, SupabaseClientOptions } from "@supabase/supabase-js";

import { Database } from "./types";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

const options: SupabaseClientOptions<"public"> = {
  global: {
    headers: {
      "x-my-custom-header": "games-hub",
    },
  },
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
  db: {
    schema: "public",
  },
};

const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, options);

export { supabase };
