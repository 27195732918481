import { createRoute } from "atomic-router";

export const routes = {
  index: createRoute(),
  auth: {
    root: createRoute(),
    signin: createRoute(),
    signup: createRoute(),
    setupProfile: createRoute(),
  },
  profile: {
    root: createRoute(),
  },
  game: createRoute<{ id: string }>(),
  notFound: createRoute(),
  test: createRoute(),
};

export const routesConfig = [
  { path: "/", route: routes.index },
  { path: "/auth/(signin|signup|setup-profile)", route: routes.auth.root },
  { path: "/auth/signin", route: routes.auth.signin },
  { path: "/auth/signup", route: routes.auth.signup },
  { path: "/auth/setup-profile", route: routes.auth.setupProfile },
  { path: "/game/:id", route: routes.game },
  { path: "/404", route: routes.notFound },
  { path: "/test", route: routes.test },
];
