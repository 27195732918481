import clsx from "clsx";
import { forwardRef, InputHTMLAttributes, memo } from "react";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  isDirty: boolean;
  error: string | undefined;
};

const InputView = forwardRef<HTMLInputElement, InputProps>(
  ({ isDirty, error, name, className, ...props }, ref) => {
    return (
      <div className="flex w-full flex-col gap-2">
        <label
          className={clsx(
            "focus-within:outline-brand-secondary flex items-center gap-2 rounded-full bg-white outline outline-4 outline-transparent",
            error && "outline-brand-primary",
            className
          )}
          htmlFor={name}
        >
          <input
            className="w-full rounded-full py-2 px-4 text-base text-black focus-within:outline-none"
            ref={ref}
            {...props}
          />
        </label>
        {isDirty && error && (
          <span className="text-brand-primary font-italic text-sm">
            {error}
          </span>
        )}
      </div>
    );
  }
);

export const Input = memo(InputView);
