import React from "react";
import type {
  AriaButtonProps,
  AriaFocusRingProps,
  HoverProps,
} from "react-aria";
import { mergeProps, useButton, useFocusRing, useHover } from "react-aria";
import type { RightJoinProps } from "shared/types";
import { twMerge } from "tailwind-merge";

import { Loader } from "../Loader";
import { useDOMRef } from "../utils";

type TAriaButtonProps = Omit<AriaButtonProps<"button">, "elementType">;

type TAriaHoverProps = HoverProps;
type TAriaFocusRingProps = AriaFocusRingProps;

type TButtonOwnProps = {
  className?: string;
  isLoading?: boolean;
};

type TButtonProps = RightJoinProps<
  TAriaButtonProps & TAriaHoverProps & TAriaFocusRingProps,
  TButtonOwnProps
>;

const _Button = React.forwardRef(function _Button(
  props: TButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    children,

    // own props
    className,
    isLoading,

    // aria hover props
    isDisabled,
    onHoverChange,
    onHoverStart,
    onHoverEnd,

    // aria focus props
    autoFocus,
    isTextInput,
    within,

    // aria button props
    excludeFromTabOrder,
    ...ariaButtonProps
  } = props;

  const buttonRef = useDOMRef(ref);

  const { buttonProps, isPressed } = useButton(
    {
      ...ariaButtonProps,
      elementType: "button",
      excludeFromTabOrder: excludeFromTabOrder ?? (isDisabled || isLoading),
      isDisabled,
      autoFocus,
      children,
    },
    buttonRef
  );

  const { hoverProps, isHovered } = useHover({
    isDisabled,
    onHoverChange,
    onHoverStart,
    onHoverEnd,
  });

  const { focusProps, isFocusVisible } = useFocusRing({
    autoFocus,
    isTextInput,
    within,
  });

  return (
    <button
      {...mergeProps(buttonProps, hoverProps, focusProps)}
      className={twMerge(
        "text-typographyinvert bg-brand-primary outline-brand-primary relative flex h-10 grow-0 select-none items-center justify-center rounded-full px-4 py-2 text-center font-semibold outline-none outline-0 transition-all duration-100",
        (isHovered || isFocusVisible) &&
          "outline-dashed outline-4 outline-offset-2",
        isPressed && "outline",
        isDisabled && "grayscale-100 pointer-events-none opacity-50",
        isLoading && "pointer-events-none",
        className
      )}
      ref={buttonRef}
    >
      {isLoading ? (
        <Loader.DotsWave dotClassName="bg-typographyinvert" />
      ) : (
        children
      )}
    </button>
  );
});

export const Button = React.memo(_Button);
