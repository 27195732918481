import { sample } from "effector";
import { changeThemeModel } from "features/changeTheme";
import { app } from "shared/libs/app";

sample({
  clock: app.started,
  source: changeThemeModel.theme.$theme,
  fn: (currentTheme) => ({ currentTheme }),
  target: changeThemeModel.theme.applyFx,
});
