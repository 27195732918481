import { ChangeTheme } from "features/changeTheme";
import { motion } from "framer-motion";
import React, { PropsWithChildren } from "react";

const AuthLayout = React.memo(({ children }: PropsWithChildren) => {
  return (
    <>
      <div className="flex h-full min-h-screen w-full flex-col items-center justify-start gap-4 overflow-hidden p-4">
        <div className="flex w-full justify-end">
          <ChangeTheme />
        </div>
        <div className="flex grow items-center">
          <motion.div
            animate={{ scale: 1, transition: { duration: 0.5 } }}
            className="bg-card w-sm flex h-full max-w-sm flex-col items-center gap-6 rounded-2xl px-6 py-8 shadow sm:gap-6"
            initial={{ scale: 0 }}
          >
            {children}
          </motion.div>
        </div>
      </div>
    </>
  );
});

export { AuthLayout };
