import {
  chainRoute,
  redirect,
  RouteInstance,
  RouteParams,
  RouteParamsAndQuery,
} from "atomic-router";
import { createEvent, merge, sample } from "effector";
import { combineEvents, condition, not } from "patronum";
import { routes } from "shared/config/routes";

import { session } from "./session";

export const chainAuthorized = <Params extends RouteParams>(
  route: RouteInstance<Params>
) => {
  const sessionCheckSuccess = createEvent();
  const sessionCheckFailure = createEvent();

  const profileCheckSuccess = createEvent();
  const profileCheckFailure = createEvent();
  const profileCheckRequiredInitialSetup = createEvent();

  const authCheckStarted = createEvent<RouteParamsAndQuery<Params>>();
  const authCheckSuccess = merge([
    combineEvents({
      events: [sessionCheckSuccess, profileCheckSuccess],
    }),
    combineEvents({
      events: [
        sessionCheckSuccess,
        profileCheckRequiredInitialSetup,
        routes.auth.setupProfile.opened,
      ],
    }),
  ]);
  const authCheckFailure = combineEvents({
    events: [sessionCheckFailure, profileCheckFailure],
  });

  // authCheckStarted.watch(() => console.log("auth check started"));
  // authCheckSuccess.watch(() => console.log("auth check success"));
  // authCheckFailure.watch(() => console.log("auth check failure"));
  // sessionCheckSuccess.watch(() => console.log("session check success"));
  // sessionCheckFailure.watch(() => console.log("session check failure"));
  // profileCheckSuccess.watch(() => console.log("profile check success"));
  // profileCheckFailure.watch(() => console.log("profile check failure"));
  // profileCheckRequiredInitialSetup.watch(() =>
  //   console.log("profile check required initial setup")
  // );

  sample({
    clock: authCheckStarted,
    filter: not(session.$session),
    target: session.receiveFx,
  });

  // check session

  sample({
    clock: combineEvents({
      events: [authCheckStarted, session.receiveFx.doneData],
    }),
    source: [],
    filter: session.$isAuthorized,
    target: sessionCheckSuccess,
  });

  sample({
    clock: combineEvents({
      events: [authCheckStarted, session.receiveFx.failData],
    }),
    source: [],
    filter: not(session.$isAuthorized),
    target: sessionCheckFailure,
  });

  redirect({
    clock: sessionCheckFailure,
    route: routes.auth.signin,
  });

  // check profile

  condition({
    source: session.$session,
    if: (s) =>
      Boolean(s?.user.user_metadata.name) &&
      Boolean(s?.user.user_metadata.avatarUrl),
    then: profileCheckSuccess,
    else: profileCheckRequiredInitialSetup,
  });

  redirect({
    clock: sample({
      clock: profileCheckRequiredInitialSetup,
      filter: not(routes.auth.setupProfile.$isOpened),
    }),
    route: routes.auth.setupProfile,
  });

  return chainRoute({
    route,
    beforeOpen: authCheckStarted,
    openOn: authCheckSuccess,
    cancelOn: authCheckFailure,
  });
};
