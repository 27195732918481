import React from "react";
import {
  AriaFieldProps,
  FocusWithinProps,
  mergeProps,
  useField,
  useFocusWithin,
  VisuallyHidden,
} from "react-aria";
import { RightJoinProps } from "shared/types";
import { twMerge } from "tailwind-merge";

import { useDOMRef } from "../utils";

type TAriaFocusWithinProps = FocusWithinProps;
type TAriaAvatarFieldProps = Omit<AriaFieldProps, "label" | "description">;

type TAvatarFieldOwnProps = {
  className?: string;
  isFocused?: boolean;
  hasError?: boolean;

  isDisabled?: boolean;

  labelClassName?: string;
  errorClassName?: string;

  name?: string;
  value?: File;
  onChange?: (file: File) => void;
  fallbackPreviewSrc?: string;
};

type TAvatarFieldProps = RightJoinProps<
  TAriaFocusWithinProps & TAriaAvatarFieldProps,
  TAvatarFieldOwnProps
>;

const _AvatarField = React.forwardRef(function _AvatarField(
  props: TAvatarFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    // own props
    className,
    hasError,
    isDisabled,
    errorClassName,
    // FIXME
    isFocused: _isFocused,

    name,
    value,
    onChange,
    fallbackPreviewSrc,

    // aria focus props
    onBlurWithin,
    onFocusWithin,
    onFocusWithinChange,

    ...avatarFieldProps
  } = props;

  const { errorMessage } = avatarFieldProps;

  const avatarFieldRef = useDOMRef(ref);

  const [isFocusWithin, setFocusWithin] = React.useState(false);

  const { fieldProps, labelProps, errorMessageProps } = useField({
    ...avatarFieldProps,
    label: "avatar",
  });

  const { focusWithinProps } = useFocusWithin({
    isDisabled,
    onBlurWithin,
    onFocusWithin,
    onFocusWithinChange: (isFocus) => {
      setFocusWithin(isFocus);
      onFocusWithinChange && onFocusWithinChange(isFocus);
    },
  });

  const shouldShowError = Boolean(errorMessage) && hasError;

  return (
    <div className="flex select-none flex-col items-center gap-2">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={twMerge(
          "flex cursor-pointer items-center gap-2 overflow-hidden rounded-full outline outline-4 outline-transparent",
          isFocusWithin && "outline-brand-secondary",
          shouldShowError && "outline-error",
          isDisabled && "cursor-default",
          className
        )}
        {...labelProps}
      >
        <img
          alt="avatar"
          className={twMerge(
            "bg-placeholder h-full w-full object-cover",
            isDisabled && "filter-grayscale-100"
          )}
          src={value ? URL.createObjectURL(value) : fallbackPreviewSrc}
        />
      </label>
      <VisuallyHidden>
        <input
          {...mergeProps(fieldProps, focusWithinProps)}
          accept="image/*"
          disabled={isDisabled}
          name={name}
          ref={avatarFieldRef}
          type="file"
          onChange={(evt) => {
            if (evt.target.files && evt.target.files[0]) {
              onChange && onChange(evt.target.files[0]);
            }
          }}
        />
      </VisuallyHidden>
      {shouldShowError && (
        <span
          {...errorMessageProps}
          className={twMerge(
            "text-error font-italic select-text text-center text-sm",
            errorClassName
          )}
        >
          {errorMessage}
        </span>
      )}
    </div>
  );
});

export const AvatarField = React.memo(_AvatarField);
