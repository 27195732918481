import { createRoutesView } from "atomic-router-react";
import { lazy, Suspense } from "react";
import { Loader } from "shared/components";
import { routes } from "shared/config/routes";
import { chainAuthorized } from "shared/libs/auth";

import { AuthLayout } from "./Layouts/AuthLayout";

// /auth
const SignInPage = lazy(() => import("./auth/signIn"));
const SignUpPage = lazy(() => import("./auth/signUp"));
const SetupProfilePage = lazy(() => import("./auth/setupProfile"));

// /
const HubPage = lazy(() => import("./HubPage"));
const GamePage = lazy(() => import("./GamePage"));
const NotFoundPage = lazy(() => import("./NotFoundPage"));

const Routing = () => {
  return (
    <Suspense fallback={<Loader.Circle />}>
      <RoutesView />
    </Suspense>
  );
};

const RoutesView = createRoutesView({
  routes: [
    {
      route: routes.auth.root,
      view: createRoutesView({
        routes: [
          { route: routes.auth.signin, view: SignInPage },
          { route: routes.auth.signup, view: SignUpPage },
          {
            route: chainAuthorized(routes.auth.setupProfile),
            view: SetupProfilePage,
          },
        ],
      }),
      layout: AuthLayout,
    },
    {
      // route: routes.index,
      route: chainAuthorized(routes.index),
      view: HubPage,
    },
    { route: routes.game, view: GamePage },
    { route: routes.notFound, view: NotFoundPage },
  ],
  // otherwise: () => <Loader.Circle />,
});

export { Routing };
