import React from "react";
import {
  AriaTextFieldOptions,
  FocusWithinProps,
  mergeProps,
  useFocusWithin,
  useTextField,
} from "react-aria";
import { RightJoinProps } from "shared/types";
import { twMerge } from "tailwind-merge";

import { useDOMRef } from "../utils";

type TAriaFocusWithinProps = FocusWithinProps;
type TAriaTextFieldProps = Omit<
  AriaTextFieldOptions<"input">,
  "inputElementType"
>;

type TTextFieldOwnProps = {
  className?: string;
  isFocused?: boolean;
  isDirty?: boolean;
  isTouched?: boolean;
  hasError?: boolean;

  labelClassName?: string;
  errorClassName?: string;
  descriptionClassName?: string;
};

type TTextFieldProps = RightJoinProps<
  TAriaFocusWithinProps & TAriaTextFieldProps,
  TTextFieldOwnProps
>;

const _TextField = React.forwardRef(function _TextField(
  props: TTextFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    // own props
    className,
    isDirty,
    hasError,
    labelClassName,
    errorClassName,
    descriptionClassName,
    // FIXME
    isFocused: _isFocused,
    isTouched: _isTouched,

    // aria focus props
    isDisabled,
    onBlurWithin,
    onFocusWithin,
    onFocusWithinChange,

    // aria text field props
    autoFocus,
    excludeFromTabOrder,
    ...textFieldProps
  } = props;

  const { label, description, errorMessage } = textFieldProps;

  const textFieldRef = useDOMRef(ref);

  const [isFocusWithin, setFocusWithin] = React.useState(autoFocus);

  const { inputProps, labelProps, descriptionProps, errorMessageProps } =
    useTextField(
      {
        ...textFieldProps,
        autoFocus,
        isDisabled,
        excludeFromTabOrder: excludeFromTabOrder ?? isDisabled,
      },
      textFieldRef
    );

  const { focusWithinProps } = useFocusWithin({
    isDisabled,
    onBlurWithin,
    onFocusWithin,
    onFocusWithinChange: (isFocus) => {
      setFocusWithin(isFocus);
      onFocusWithinChange && onFocusWithinChange(isFocus);
    },
  });

  const shouldShowError = Boolean(errorMessage) && hasError && isDirty;

  return (
    <div className="flex gap-2">
      {label && (
        <label
          className={twMerge(
            "h-10 w-fit cursor-pointer select-none text-sm font-semibold leading-10",
            isDisabled && "cursor-default text-gray-500",
            labelClassName
          )}
          {...labelProps}
        >
          {label}
        </label>
      )}
      <div className="flex w-full flex-col gap-2">
        <input
          {...mergeProps(inputProps, focusWithinProps)}
          className={twMerge(
            "placeholder:text-placeholder w-full appearance-none rounded-full px-4 py-2 text-black outline outline-4 outline-transparent disabled:bg-white",
            isFocusWithin && "outline-brand-secondary",
            shouldShowError && "outline-error",
            isDisabled && "disabled:opacity-50",
            className
          )}
          ref={textFieldRef}
        />
        {(shouldShowError || description) && (
          <div className="ml-4 flex flex-col gap-1 text-sm">
            {shouldShowError && (
              <span
                {...errorMessageProps}
                className={twMerge("text-error font-italic", errorClassName)}
              >
                {errorMessage}
              </span>
            )}
            {description && (
              <span
                className={twMerge("text-description", descriptionClassName)}
                {...descriptionProps}
              >
                {description}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export const TextField = React.memo(_TextField);
