import React from "react";
import type {
  AriaFocusRingProps,
  AriaToggleButtonProps,
  HoverProps,
} from "react-aria";
import {
  mergeProps,
  useFocusRing,
  useHover,
  useToggleButton,
} from "react-aria";
import type { ToggleProps } from "react-stately";
import { useToggleState } from "react-stately";
import type { RightJoinProps } from "shared/types";
import { twMerge } from "tailwind-merge";

import { useDOMRef } from "../utils";

type TToggleStateProps = Pick<
  ToggleProps,
  | "autoFocus"
  | "defaultSelected"
  | "isSelected"
  | "onBlur"
  | "onChange"
  | "onFocus"
  | "onKeyDown"
  | "onFocusChange"
  | "onKeyUp"
>;

type TAriaToggleButtonProps = Omit<
  AriaToggleButtonProps<"button">,
  "elementType" | "type" | "children"
>;

type TAriaHoverProps = HoverProps;
type TAriaFocusRingProps = AriaFocusRingProps;

type TToggleOwnProps = {
  className?: string;
  isSelectedNode: React.ReactNode;
  isUnselectedNode: React.ReactNode;
  isSelectedClassName?: string;
  isUnselectedClassName?: string;
};

type TToggleProps = RightJoinProps<
  RightJoinProps<TToggleStateProps, TAriaToggleButtonProps> &
    TAriaHoverProps &
    TAriaFocusRingProps,
  TToggleOwnProps
>;

const _Toggle = React.forwardRef(function _Toggle(
  props: TToggleProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    // own props
    className,
    isSelectedNode,
    isUnselectedNode,
    isSelectedClassName,
    isUnselectedClassName,

    // aria state props
    defaultSelected,
    isSelected,
    onBlur,
    onChange,
    onFocus,
    onFocusChange,
    onKeyDown,
    onKeyUp,

    // aria hover props
    isDisabled,
    onHoverChange,
    onHoverEnd,
    onHoverStart,

    // aria focus props
    autoFocus,
    isTextInput,
    within,

    // aria toggle button props
    excludeFromTabOrder,
    ...toggleButtonProps
  } = props;

  const toggleRef = useDOMRef(ref);

  const state = useToggleState({
    autoFocus,
    defaultSelected,
    isDisabled,
    isSelected,
    onBlur,
    onChange,
    onFocus,
    onFocusChange,
    onKeyDown,
    onKeyUp,
  });

  const { buttonProps, isPressed } = useToggleButton(
    {
      ...toggleButtonProps,
      excludeFromTabOrder: excludeFromTabOrder ?? isDisabled,
      isDisabled,
      defaultSelected,
      isSelected,
      autoFocus,
      onChange,
      onBlur,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
    },
    state,
    toggleRef
  );

  const { hoverProps, isHovered } = useHover({
    isDisabled,
    onHoverChange,
    onHoverEnd,
    onHoverStart,
  });

  const { focusProps, isFocusVisible } = useFocusRing({
    autoFocus,
    isTextInput,
    within,
  });

  return (
    <button
      {...mergeProps(buttonProps, hoverProps, focusProps)}
      className={twMerge(
        "outline-brand-primary appearance-none rounded-full bg-transparent p-2 font-semibold outline-none outline-0 transition-all duration-100",
        (isHovered || isFocusVisible) &&
          "outline-dashed outline-4 outline-offset-2",
        isPressed && "outline",
        isDisabled && "pointer-events-none grayscale",
        className,
        isSelected ? isSelectedClassName : isUnselectedClassName
      )}
      ref={toggleRef}
      type="button"
    >
      {isSelected ? isSelectedNode : isUnselectedNode}
    </button>
  );
});

export const Toggle = React.memo(_Toggle);
