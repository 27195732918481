import { App } from "app";
import { createRoot } from "react-dom/client";
import { createWrapperAndAppendToBody } from "shared/utils/createWrapperAndAppendToBody";

let appRootContainer = document.getElementById("app-root");

if (!appRootContainer) {
  appRootContainer = createWrapperAndAppendToBody("app-root", true);
}

createRoot(appRootContainer).render(
  // <StrictMode>
  <App />
  // </StrictMode>
);
