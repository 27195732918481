import clsx from "clsx";
import { FormHTMLAttributes, memo, PropsWithChildren } from "react";

export type FormProps = FormHTMLAttributes<HTMLFormElement> & PropsWithChildren;

const FormView = ({ children, className, ...props }: FormProps) => {
  return (
    <form
      className={clsx(
        "flex w-full flex-col items-center gap-6 text-black",
        className
      )}
      {...props}
    >
      {children}
    </form>
  );
};

export const Form = memo(FormView);
