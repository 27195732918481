import { motion, Transition, Variants } from "framer-motion";
import { memo, SVGAttributes } from "react";
import { twMerge } from "tailwind-merge";

const outerCircleVariants: Variants = {
  animate: {
    strokeWidth: 6,
    strokeDasharray: "0 50 0",
    rotate: -360,
  },
};
const outerCircleTransition: Transition = {
  duration: 1,
  repeat: Infinity,
  easings: ["easeIn"],
};

const innerCircleVariants: Variants = {
  animate: {
    strokeWidth: [6, 10, 6],
    strokeDasharray: "0 32 0",
    rotate: 360,
  },
};
const innerCircleTransition: Transition = {
  duration: 0.6,
  repeat: Infinity,
  easings: ["easeIn"],
};

type TCircleLoaderOwnProps = {
  outerCircleClassName?: string;
  innerCircleClassName?: string;
};
type TCircleLoaderProps = SVGAttributes<SVGElement> & TCircleLoaderOwnProps;

const _CircleLoader = (props: TCircleLoaderProps) => {
  const { className, outerCircleClassName, innerCircleClassName } = props;

  return (
    <svg
      className={twMerge("h-16 w-16", className)}
      viewBox="0 0 100 100"
      {...props}
    >
      <motion.ellipse
        animate="animate"
        className={twMerge("text-brand-secondary", outerCircleClassName)}
        cx="50"
        cy="50"
        fill="transparent"
        rx="47"
        ry="47"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="6"
        transition={outerCircleTransition}
        variants={outerCircleVariants}
      />
      <motion.ellipse
        animate="animate"
        className={twMerge("text-brand-primary", innerCircleClassName)}
        cx="50"
        cy="50"
        fill="transparent"
        rx="30"
        ry="30"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="6"
        transition={innerCircleTransition}
        variants={innerCircleVariants}
      />
    </svg>
  );
};

export const CircleLoader = memo(_CircleLoader);
