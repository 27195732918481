import { createHistoryRouter } from "atomic-router";
import { pipe } from "rambda";

import { withRouter } from "./withRouter";

type TProvidersOptions = {
  router: ReturnType<typeof createHistoryRouter>;
};

export const withProviders = ({ router }: TProvidersOptions) =>
  pipe(withRouter(router));
