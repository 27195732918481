import clsx from "clsx";
import { forwardRef, InputHTMLAttributes, memo } from "react";

export type InputImageProps = InputHTMLAttributes<HTMLInputElement> & {
  value: File | null;
  error: string | undefined;
  fallbackPreviewUrl?: string;
};

const InputImageView = forwardRef<HTMLInputElement, InputImageProps>(
  ({ value, error, fallbackPreviewUrl, name, className, ...props }, ref) => {
    return (
      <div className={clsx("flex w-full flex-col gap-2", className)}>
        <label
          className={clsx(
            "sm:w-42 sm:h-42 h-28 w-28 cursor-pointer self-center overflow-hidden rounded-full border-4 object-cover",
            !error && "border-brand-secondary",
            error && "border-brand-primary"
          )}
          htmlFor={name}
        >
          <input hidden accept="image/*" ref={ref} type="file" {...props} />
          <button>
            <img
              alt="avatar"
              className="h-full w-full object-cover"
              src={value ? URL.createObjectURL(value) : fallbackPreviewUrl}
            />
          </button>
        </label>
        {error && (
          <span className="text-brand-primary font-italic text-sm">
            {error}
          </span>
        )}
      </div>
    );
  }
);

export const InputImage = memo(InputImageView);
