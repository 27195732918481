import { reflect } from "@effector/reflect";
import { Toggle } from "shared/components";
import { MoonIcon, SunIcon } from "shared/icons";

import { theme } from "../model";
import { TTheme } from "../model/models";

type TChangeThemeProps = {
  currentTheme: TTheme;
};

const ChangeThemeView = ({ currentTheme }: TChangeThemeProps) => {
  return (
    <Toggle
      isSelected={currentTheme === "dark"}
      isSelectedNode={<SunIcon className="h-6 w-6" />}
      isUnselectedNode={<MoonIcon className="h-6 w-6" />}
      onChange={(isSelected) => theme.setted(isSelected ? "dark" : "light")}
    />
  );
};

export const ChangeTheme = reflect({
  view: ChangeThemeView,
  bind: {
    currentTheme: theme.$theme,
  },
});
