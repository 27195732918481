import { createHistoryRouter, redirect } from "atomic-router";
import { createBrowserHistory } from "history";
import { routes, routesConfig } from "shared/config/routes";

const router = createHistoryRouter({
  routes: routesConfig,
});

router.initialized.watch(() => console.log("router is initialized"));

router.setHistory(createBrowserHistory());

redirect({
  clock: router.routeNotFound,
  route: routes.notFound,
});

export { router };
