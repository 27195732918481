import type { RouteParams } from "atomic-router";
import type { LinkProps } from "atomic-router-react";
import { Link as AtomicLink } from "atomic-router-react";
import React from "react";
import type {
  AriaFocusRingProps,
  AriaLinkOptions,
  HoverProps,
} from "react-aria";
import { mergeProps, useFocusRing, useHover, useLink } from "react-aria";
import type { RightJoinProps } from "shared/types";
import { twMerge } from "tailwind-merge";

import { useDOMRef } from "../utils";

type TAriaLinkOptions = Omit<AriaLinkOptions, "elementType">;
type TAriaHoverProps = HoverProps;
type TAriaFocusRingProps = AriaFocusRingProps;

type TLinkProps<Params extends RouteParams> = RightJoinProps<
  TAriaLinkOptions & TAriaHoverProps & TAriaFocusRingProps,
  RightJoinProps<LinkProps<Params>, TAriaLinkOptions>
>;

const _Link = React.forwardRef(function _Link<Params extends RouteParams>(
  props: TLinkProps<Params>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const {
    children,
    className,

    // aria link options
    onBlur,
    onFocus,
    onFocusChange,
    onKeyDown,
    onKeyUp,
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,

    // aria hover props
    isDisabled,
    onHoverChange,
    onHoverStart,
    onHoverEnd,

    // aria focus props
    autoFocus,
    isTextInput,
    within,

    // atomic link props
    ...atomicLinkProps
  } = props;

  const linkRef = useDOMRef(ref);

  const { linkProps, isPressed } = useLink(
    {
      elementType: "a",
      autoFocus,
      isDisabled,
      onBlur,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      onPress,
      onPressChange,
      onPressEnd,
      onPressStart,
      onPressUp,
      "aria-describedby": atomicLinkProps["aria-describedby"],
      "aria-details": atomicLinkProps["aria-details"],
      "aria-label": atomicLinkProps["aria-label"],
      "aria-labelledby": atomicLinkProps["aria-labelledby"],
    },
    linkRef
  );

  const { hoverProps, isHovered } = useHover({
    isDisabled,
    onHoverChange,
    onHoverStart,
    onHoverEnd,
  });

  const { focusProps, isFocusVisible } = useFocusRing({
    autoFocus,
    isTextInput,
    within,
  });

  return (
    <AtomicLink
      {...mergeProps(linkProps, hoverProps, focusProps, atomicLinkProps)}
      className={twMerge(
        "text-brand-primary underline-offset-6 w-fit font-semibold decoration-0 outline-none transition-all duration-100",
        (isHovered || isFocusVisible) &&
          "underline decoration-dashed decoration-4",
        isPressed && "decoration-solid",
        isDisabled && "pointer-events-none grayscale",
        className
      )}
      ref={linkRef}
      tabIndex={isDisabled ? -1 : undefined}
    >
      {children}
    </AtomicLink>
  );
});

export const Link = React.memo(_Link);
