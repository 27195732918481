import { createEffect, createEvent, sample } from "effector";
import { createPersistedStore } from "shared/utils/createPersistedStore";

import { TTheme } from "./models";

const applyFx = createEffect<
  {
    currentTheme: TTheme;
    newTheme?: TTheme;
  },
  TTheme
>(async ({ currentTheme, newTheme }) => {
  if (document.body.classList.contains(currentTheme) && newTheme) {
    document.body.classList.replace(currentTheme, newTheme);
  } else {
    document.body.classList.add(currentTheme);
  }

  return newTheme ?? currentTheme;
});

const setted = createEvent<TTheme>();

const $theme = createPersistedStore<TTheme>("light", {
  name: "theme",
});
$theme.on(applyFx.doneData, (_, payload) => payload);

sample({
  clock: setted,
  source: $theme,
  fn: (currentTheme, newTheme) => ({ currentTheme, newTheme }),
  target: applyFx,
});

export const theme = {
  $theme,
  setted,
  applyFx,
};
